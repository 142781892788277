<template>
	<div class="site__navigation" @click='toggleBurger'>
		<button class="site__navigation__menu_burger">
			<span class="menu_burger__lines"></span>
			<span class="site__navigation__menu__text">
				Меню
			</span>
		</button>
		<nav class="site__navigation__bar">
			<ul id="menu-glavnoe" class="menu nav-menu">
				<li v-for="category in rubrics" :key="category.id" class="menu-item">
					<router-link :to="{ name: 'post-list', params: { rubricSlug: category.id } }">{{ category.name }}</router-link>
				</li>
			</ul>
		</nav>
		<SearchBar class="site__navigation__search__form"></SearchBar>
	</div>
</template>

<script>
import { getRequest } from '@/modules/request';
import SearchBar from './SearchBar.vue';
import { useRubricStore } from '@/store';

export default {
	data() {
		return {
			rubrics: [{ name: 'Главная', slug: '' }],
            rubricsStore: useRubricStore()
		}
	},
	components: {
		SearchBar
	},
	methods: {
		toggleBurger() {
			document.body.classList.toggle('lock');
			document.querySelector('.menu_burger__lines').classList.toggle('menu_burger-active');
			document.querySelector('.site__navigation__bar').classList.toggle('nav-active');
		}
	},
	async created() {
		const req = await getRequest("rubrics");
		if (req && req.status === "success") {
			this.rubricsStore.setRubrics(req.data);
			req.data.forEach(nav => {
				this.rubrics.push(nav);
			});
		}
	}

}
</script>

<style scoped>
.site__navigation__search__form {
	position: absolute;
	top: 0;
	right: 0;
}

.site__navigation {
	border-top: solid 1px #ddd;
	border-bottom: solid 1px #ddd;

	line-height: 58px;
	position: relative;
}

#menu-glavnoe {
	display: flex;
	flex-direction: column;

	text-transform: uppercase;
	font-family: 'Oswald';
}

#menu-glavnoe .router-link-exact-active {
	color: #a62425;
}

.menu-item a {
	white-space: nowrap;
	font-size: 93.8%;
	display: block;
}

.menu-item {
	border-top: solid 1px #ddd;
}

.menu-item:hover {
	color: #b85051;
	opacity: 1;
	cursor: pointer;
}

@media screen and (min-width: 783px) {
	#menu-glavnoe {
		flex-wrap: wrap;
		justify-content: center;
		flex-direction: row;
		padding-right: 30px;
	}

	.menu-item a {
		text-align: center;
	}

	.menu-item a {
		padding: 0 18px;
	}

	.site__navigation__menu_burger {
		display: none;
	}

	.menu-item {
		border-top: none;
	}
}

@media screen and (max-width: 783px) {
	.site__navigation__bar {
		display: none;
	}

	.site__navigation__bar.nav-active {
		display: block;
		position: absolute;
		width: 100%;
		background: white;
	}
}

@media print {
	.site__navigation {
		display: none;
		visibility: hidden;
	}
}

/* Menu burger */
.site__navigation__menu_burger {
	width: 100%;
	text-align: left;
	line-height: inherit;
	position: relative;
}

.menu_burger__lines {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.menu_burger__lines::before,
.menu_burger__lines::after,
.menu_burger__lines {
	position: absolute;
	left: 0;
	width: 15px;
	height: 2px;
	background-color: #000;
	-webkit-transition: 0.4s;
	-moz-transition: 0.4s;
	-o-transition: 0.4s;
	transition: 0.4s;
}

.menu_burger__lines::before {
	content: '';
	top: -4px;
}

.menu_burger__lines::after {
	content: '';
	top: 4px;
}


.menu_burger-active.menu_burger__lines {
	background-color: transparent;
}

.menu_burger-active.menu_burger__lines::before {
	top: 0;
	transform: rotate(45deg);
}

.menu_burger-active.menu_burger__lines::after {
	top: 0;
	transform: rotate(-45deg);
}



.site__navigation__menu__text {
	padding-left: 25px;
	color: #000;
	text-transform: uppercase;
}
</style>