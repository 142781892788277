<template>
	<div>{{ dateFormat(date) }}</div>
</template>

<script>
export default {
	name: 'date-layout',
	props: {
		date: {
			type: String,
			required: true
		},
		time: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		dateFormat(string) {

			const inputDate = new Date(string);
			let options = { day: 'numeric', month: 'long', year: 'numeric' };
			if(this.time) {
				options.hour = '2-digit';
				options.minute = '2-digit';
			}
			const formattedDate = inputDate.toLocaleString('ru-RU', options);
			const parts = formattedDate.split(' ');
			parts[1] = parts[1].charAt(0).toUpperCase() + parts[1].substring(1, 3);
			parts[3] = ""

			return parts.join(' ');
		}
	}
}
</script>

<style scoped></style>