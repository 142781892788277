<template>
	<header class="header">
		<div class="branding">
			<h1 class="branding__title">
				<router-link to="/">Мой путь инвестора</router-link>
			</h1>
			<div class="branding__description">Стратегия ленивой инвестиционной блондинки</div>
		</div>
		<NavBar />
	</header>
</template>

<script>
import HeaderNavBar from './HeaderNavBar.vue';

export default {
	name: 'site-header',
	components: {
		NavBar: HeaderNavBar
	}
}
</script>

<style>
.header {
	margin-bottom: 75px;

	position: sticky;
	top: 0;
	z-index: 5;

	background-color: white;

}
.branding {
	text-align: center;
	padding: 25px 0 40px 0;
}

.branding__title a {
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.01em;
}

@media screen and (min-width: 783px) {
	.branding {
		padding: 40px 0 50px 0;
	}
	.branding__title a {
		font-size: 54px;
	}
}

.branding__description {
	color: #777;
	font-style: italic;
	line-height: 1.4;
	padding-top: 4px;
}

@media print {
	.header {
		position: relative;
	}
}
</style>