import { createApp } from 'vue';
import App from './App.vue';

import router from '@/router';
import { createPinia } from "pinia";

import components from '@/UI';

const app = createApp(App);
app.use(router);
app.use(createPinia());
components.forEach(component => {
	app.component(component.name, component);
});

app.mount('#app');
