import { defineStore } from 'pinia';

export const useRubricStore = defineStore('rubricStore', {
    state: () => ({
        rubrics: [],
    }),

    actions: {
        setRubrics(rubrics) {
            this.rubrics = rubrics;
        },
    },

    getters: {
        findRubric: (state) => (rubricId) => {
           if(Array.isArray(state.rubrics)) {
               return state.rubrics.find((rubric) => {
                    return rubric.id === rubricId;
               });
           }
           return null;
        },
    },
});
