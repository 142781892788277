<template>
    <div class="site">
        <div class="page">
            <ErrorBoundary>
                <TheHeader/>
                <div class="content">
                    <router-view></router-view>
                    <EmailSubscribe/>
                </div>
                <TheFooter/>
            </ErrorBoundary>
        </div>
    </div>
    <tg-link class="tg__link"></tg-link>
</template>

<script>
import { TheHeader } from '@/modules/header';
import { EmailSubscribe } from '@/modules/subscribe';
import { ErrorBoundary } from "@/modules/boundary";

import TgLink from './components/TgLink.vue';
import TheFooter from '@/components/TheFooter.vue';

export default {
    name: 'App',
    components: {
        TheHeader,
        TheFooter,
        EmailSubscribe,
        TgLink,
        ErrorBoundary
    }
}

</script>

<style>
@import '@/assets/reset_style.css';
@import '@/assets/style.css';
@import url('/public/css/post_style.css');

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: #fff;

    color: #333;
    font-family: 'Lora', serif;
    font-size: 17px;
    line-height: 1.6;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.lock {
    overflow-y: hidden;
}


#app {
    min-height: 100%;

}

@media screen and (min-width: 783px) {
    body {
        font-size: 18px;
    }

    body.lock {
        overflow-y: auto;
    }
}

/* Content */
#app {
    margin: 0 20px;
}

@media screen and (min-width: 783px) {
    #app {
        margin: 0 30px
    }
}

@media screen and (min-width: 980px) {
    #app {
        margin: 0 40px;
    }

    .site {
        max-width: 1120px;
        margin: 0 auto;
    }
}

/* TODO: change to router-link */
a {
    color: inherit;
    text-decoration: none;
    transition: background-color .2s ease-in-out, border-color .2s ease-in-out, color .2s ease-in-out, opacity .2s ease-in-out;
}

a:hover {
    opacity: 0.8;
}

@media screen and (min-width: 980px) {
    .content {
        width: 700px;
        margin: 0 auto;
    }
}

/* page header */
.page__title {
    background-color: rgba(0, 0, 0, .04);
    padding: 25px;
    margin: 0 0px 60px;
    text-align: center;
}

/* telegram link icon */
.tg__link {
    position: fixed;
    right: 20px;
    bottom: 20px;
}

@media screen and (min-width: 980px) {
    .content__area {
        width: 700px;
        margin: 0 auto;
    }
}

@media print {

    html,
    body {
        color: #000 !important;
        font-size: 10pt !important;
    }

    .tg__link {
        display: none;
    }
}
</style>
