<script>
export default { name: "category-header" };
</script>
<script setup>
import {defineProps} from 'vue'
import {useRouter} from 'vue-router'

const router = useRouter()

const props = defineProps({
    title: String,
    description: String,
    breadcrumbs: {
        type: Boolean,
        default: false
    },
    post: Object,
    rubric: Object
});
</script>

<template>
    <header class="content__header">
        <div>
            <h1 class="post-title" v-if="props.title">{{ props.title }}</h1>
            <p class="description" v-if="props.description">{{ props.description }}</p>
        </div>
        <div class="bread_crumbs">
            <router-link :to="router.currentRoute.fullPath = '/'" id="static_green">
                Главная
            </router-link>
            <span v-if="props.rubric">
				» {{ props.rubric.name }}
			</span>
            <span v-if="props.post" aria-disabled="true">
				»
			</span>
            <router-link v-if="props.post" id="static_green" :to="'/' + props.post.rubric.id">
                 {{ props.post.rubric.name }}
            </router-link>
            <span v-if="props.post">
				» {{ props.post.title }}
			</span>
            <br/>
        </div>
    </header>
</template>


<style>
.bread_crumbs {
    font-family: 'Oswald', serif;
    font-size: 0.85em;
    text-align: center;
    margin: 25px 0 0 0;
    color: #777;
}

#static_green {
    color: #7ab80e;
}

.description {
    font-style: italic;
    color: #777;
}

.post-title {
    font-size: 42px;
}

.content__header {
    margin: 0 auto 60px auto;
    text-align: center;
    background-color: rgba(0, 0, 0, .04);
    padding: 25px;
}</style>