<template>
	<footer class="footer">
		© 2023 <router-link class="home__link" to="/">Мой путь инвестора</router-link>
	</footer>
</template>

<style scoped>
.footer {
	text-align: center;
	color: #999;
	font-size: 87%;
	padding: 28px 0;
	border-top: solid 1px #ddd;
}

.home__link {
	color: #666;
}

.home__link:hover {
	color: #111;
}

</style>