<template>
	<div class="subscribe">
		<form @submit.prevent="submitForm" v-if="!success" class="subscribe__form">
			<div>Подпишитесь на нашу рассылку и присоединяйтесь к 562 остальным подписчикам.</div>
			<label>
				<span class="subscribe__email__text">Адрес электронной почты <span class="required">*</span></span>
				<input 
					placeholder="example@mail.ru" 
					v-model.trim:value="v$.inputEmail.$model"
					:error="v$.inputEmail.$errors" 
					type="text" 
					class="subscribe__email__input">
				<div v-for="error of v$.$errors" :key="error.$uid"
					class="subscribe__validation__error subscribe__error__list">
					<div class="form_error_message">Пожалуйста, введите действительный адрес электронной почты</div>
				</div>
			</label>
			<button v-if="!loading" type="submit" class="subscribe__button black__button">Подписаться!</button>
			<span v-else class="loading__animation">
				<span class="first"></span>
				<span class="second"></span>
				<span class="third"></span>
			</span>
		</form>
		<div v-else class="subscribe__message">
			<p class="subscribe__success">Проверьте ваш почтовый ящик или спам, чтобы подтвердить свою подписку.</p>
			<!-- TODO: validation from backend -->
			<p class="subscribe__validation__error"></p>
		</div>
	</div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

// eslint-disable-next-line
const regExp = helpers.regex(/^([a-z0-9_\.-]{1,64})@(?:[a-z0-9_-]+|[a-z0-9_-]+(\.{1}[a-z0-9_-]+))\.([a-z]{2,6}$)/);
							
export default {
	setup() {
		return {
			v$: useVuelidate()
		}
	},
	data() {
		return {
			inputEmail: '',
			success: false,
			loading: false,
			error: {
				type: Array,
				required: false
			}

		}
	},
	validations() {
		return {
			inputEmail: { regExp }
		}
	},

}

</script>

<style>
.subscribe {
	padding: 25px;
	border: 5px solid rgba(0, 0, 0, .04);
	margin: 80px auto;
	line-height: 20px;
}

.subscribe__form {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.subscribe__email__input {
	width: 100%;
}

.subscribe__email__text {
	font-weight: bold;
}

.subscribe__button {
	margin: 0 !important;
}

.subscribe__success {
	color: #468847;
}

.subscribe__validation__error {
	color: #b94a48;
}

.subscribe__error__list {
	font-size: .9em;
	line-height: .9em;
	margin: 8px 0 3px;
	transition: all .3s ease-in;
}

.subscribe__message {
	margin: 70px 20px;
}

.loading__animation>span {
	-webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
	animation: bouncedelay 1.4s infinite ease-in-out both;
	background-color: #5b5b5b;
	border-radius: 100%;
	display: inline-block;
	height: 5px;
	width: 5px;
}

.loading__animation .first {
	-webkit-animation-delay: -.32s;
	animation-delay: -.32s;
}

.loading__animation .second {
	-webkit-animation-delay: -.16s;
	animation-delay: -.16s;
	margin: 0 7px;
}

@keyframes bouncedelay {

	0%,
	80%,
	100% {
		transform: scale(0);
	}

	40% {
		transform: scale(1);
	}
}
</style>