<script setup>
import {ref } from 'vue';

var error = ref(null);

// onErrorCaptured((err) => {
//     error.value = err;
//     console.log("env: ", process.env.NODE_ENV + ' '+error.value)
//         error.value = err;
//     if (process.env.NODE_ENV === 'production') {
//         return;// error boundary works only on development mode
//     }
//     return false;
// })
</script>

<template>
    <div>
        <div v-if="error" style="margin-top: 50px;">
            <h1 class="page__title">Ошибка</h1>
            <p class="post__content">Нашли ошибку? Сообщите об ошибке <a href="mailto:example@mail.ru">example@mail.ru</a></p>
        </div>
        <div v-else>
            <slot></slot>
        </div>
    </div>
</template>
